import medium from './medium.svg';
import x from './x.svg';
import telegram from './telegram.svg';
import discord from './discord.svg';
import facebook from './facebook.svg';
import github from './github.svg';
import reddit from './reddit.svg';
import website from './website.svg';

export default { medium, x, telegram, discord, facebook, github, reddit, website };
