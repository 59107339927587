/**
 * @file settings
 * @author atom-yang
 */

export default class Settings {
  constructor() {
    this.defaultAccount = undefined;
  }
}
